
export default {
  name: 'TheSimpleTimer',
  props: {
    totalTime: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      time: null
    };
  },

  computed: {
    timer () {
      const minutes = Math.floor(this.time % (1000 * 60 * 60) / (1000 * 60));
      const seconds = Math.floor(this.time % (1000 * 60) / 1000);

      return {
        minutes: minutes < 10 ? '0' + minutes : minutes,
        seconds: seconds < 10 ? '0' + seconds : seconds
      };
    }
  },

  mounted () {
    this.time = this.totalTime;
    this.timerInit();
  },

  methods: {
    timerInit () {
      this.$emit('timer-start');

      const interval = setInterval(() => {
        this.time -= 1000;

        if (this.time < 400) {
          clearInterval(interval);
          this.$emit('timer-end');
        }
      }, 1000);
    }
  }
};
