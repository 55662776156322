import { validationMixin } from 'vuelidate';
export default {
  mixins: [validationMixin],
  provide () {
    const validation = {};
    Object.defineProperty(validation, '$v', {
      enumerable: true,
      get: () => this.$v.form
    });
    Object.defineProperty(validation, 'serverErrors', {
      enumerable: true,
      get: () => this.serverErrors,
      set (newValue) {
        this.serverErrors = newValue;
      }
    });
    Object.defineProperty(validation, 'validators', {
      enumerable: true,
      get: () => this.validators,
      set (newValue) {
        this.validators = newValue;
      }
    });
    Object.defineProperty(validation, 'form', {
      enumerable: true,
      get: () => this.form,
      set (newValue) {
        this.form = newValue;
      }
    });

    return { validation };
  },
  validations () {
    return this.validators;
  },
  data: () => ({
    serverErrors: {},
    form: {},
    isSubmitDisabled: true,
    validators: {
      form: {

      }
    }
  }),
  computed: {
    formInvalid () {
      return !Object.keys(this.form).length || this.$v.form.$invalid;
    }
  },
  watch: {
    formInvalid (val) {
      this.isSubmitDisabled = val;
    }
  },
  methods: {
    setServerError (field, message) {
      this.$set(this.serverErrors, field, message);
    }
  }
};
