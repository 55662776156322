

export default {
  name: 'SmsCodeInput',

  props: {
    codeErrors: {
      type: String,
      required: true
    },

    codeMaxLength: {
      type: Number,
      default: 4
    }
  },

  data () {
    return {
      codeValues: [],
      code: undefined
    };
  },

  mounted () {
    this.$refs['code-1']?.[0]?.focus();
  },

  methods: {
    handleInput (event, index) {
      if (event.inputType === 'insertFromPaste') {
        return;
      }

      const value = event.target.value;
      this.$emit('clearError');

      if (value) {
        this.codeValues[index - 1] = value;

        if (index < this.codeMaxLength) {
          this.$refs[`code-${ index + 1 }`][0].focus();
        }

        this.code = this.codeValues.join('');

        if (this.code.length === this.codeMaxLength) {
          this.$emit('change', this.code);
        }
      }
    },

    handlePaste (event) {
      const pastedValue = event.clipboardData.getData('text');
      const cycleLength = pastedValue.length > this.codeMaxLength ? this.codeMaxLength : pastedValue.length;

      if (/^\d+$/.test(pastedValue)) {
        for (let i = 0; i < cycleLength; i++) {
          this.codeValues[i] = pastedValue[i];

          const codeInput = this.$refs[`code-${ i + 1 }`][0];
          codeInput.value = pastedValue[i];
          codeInput.focus();
        }

        this.code = this.codeValues.join('');

        if (this.code.length === this.codeMaxLength) {
          this.$emit('change', this.code);
        }
      } else {
        event.preventDefault();
      }
    },

    preventNonNumeric (event, index) {
      if (event.inputType === 'insertFromPaste') {
        return;
      }

      const value = event.target.value;

      if (event.data !== null) {
        if (!/^\d*$/.test(event.data)) {
          event.preventDefault();
        }

        if (index < this.codeMaxLength && value) {
          this.$refs[`code-${ index + 1 }`][0].focus();
        }
      }
    },

    handleKey (event, index) {
      if (
        event.key === 'Backspace' &&
        index > 1 &&
        !this.codeValues[index - 1]
      ) {
        this.$emit('clearError');
        this.$refs[`code-${ index - 1 }`][0].focus();
      }
    }
  }
};
