import { render, staticRenderFns } from "./Auth.vue?vue&type=template&id=287e2877"
import script from "./Auth.vue?vue&type=script&lang=js"
export * from "./Auth.vue?vue&type=script&lang=js"
import style0 from "./Auth.vue?vue&type=style&index=0&id=287e2877&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

/* custom blocks */
import block0 from "./Auth.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VForm: require('/app/common/components/ui/VForm/VForm.vue').default,SmsCodeInput: require('/app/src/components/pages/login/SmsCodeInput.vue').default,TheSimpleTimer: require('/app/src/components/common/TheSimpleTimer.vue').default})
