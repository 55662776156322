
import {
  validationMixin
} from 'vuelidate';
import {
  required,
  minLength,
  maxLength,
  numeric
} from 'vuelidate/lib/validators';
import VForm from '@@/common/components/ui/VForm/VForm.vue';
import {
  SButton,
  SInput
  // SIcon
} from '@strana-artw/ui-kit';
import { PolicyOutput } from '@strana-artw/cabinets-common';
import { mapActions } from 'pinia';
import Validator from '@/mixins/validator';
import {
  phoneValidator
} from '@/shared/lib/utils/validators';
import TheSimpleTimer from '@/components/common/TheSimpleTimer.vue';
import SmsCodeInput from '@/components/pages/login/SmsCodeInput.vue';
import { useAuthStore } from '@/store/authentication';
import { ENV_VARIABLES } from '@/app/config/environment';

export default {
  name: 'Auth',

  components: {
    SmsCodeInput,
    TheSimpleTimer,
    PolicyOutput,
    VForm,
    SInput,
    SButton
    // SIcon
  },

  mixins: [
    Validator,
    validationMixin
  ],

  validations: {
    phone: {
      isPhone: phoneValidator,
      required,
      minLength,
      serverError () {
        return !this.hasServerError('phone');
      }
    },

    code: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(4),
      numeric,
      serverError () {
        return !this.hasServerError('code');
      }
    }
  },

  data () {
    return {
      host: ENV_VARIABLES.HOST,
      codeValues: [],
      isCodeSent: false,
      phone: '',
      code: '',
      isTimerEnd: false,
      serverErrors: {}
    };
  },

  head () {
    return {
      title: this.$t('pageTitle'),
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.$t('pageTitle')
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.$t('pageDescription')
        },
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pageDescription')
        }
      ]
    };
  },

  computed: {
    phoneErrors () {
      if (!this.$v.phone.$dirty) {
        return '';
      }
      const {
        required,
        minLength,
        isPhone,
        serverError
      } = this.$v.phone;

      if (!required) {
        return this.$t('phoneErrors.required');
      }

      if (!minLength) {
        return this.$t('phoneErrors.minLength');
      }

      if (!isPhone) {
        return this.$t('phoneErrors.isPhone');
      }

      if (!serverError) {
        return this.serverErrors.phone || this.$t('phoneErrors.serverError');
      }

      return '';
    },

    codeErrors () {
      if (!this.$v.code.$dirty) {
        return '';
      }
      const {
        required,
        numeric,
        minLength,
        maxLength,
        serverError
      } = this.$v.code;

      if (!required) {
        return this.$t('codeErrors.required');
      }

      if (!numeric || !minLength || !maxLength) {
        return this.$t('codeErrors.isCode');
      }

      if (!serverError) {
        return this.serverErrors.code || this.$t('codeErrors.serverError');
      }

      return '';
    },

    isPhoneInvalid () {
      return this.$v.phone.$invalid;
    },

    isCodeInvalid () {
      return this.$v.code.$invalid;
    }
  },

  mounted () {
    if (this.$auth.loggedIn) {
      this.$router.push('/');
    }
  },

  methods: {
    ...mapActions(useAuthStore, [
      'sendCode',
      'login'
    ]),

    hasServerError (field) {
      return Boolean(this.serverErrors[field]);
    },

    async sendCodeHandler () {
      if (this.isPhoneInvalid) {
        return;
      }

      try {
        await this.sendCode({
          phone: this.phone
        });
        this.isCodeSent = true;
      } catch (error) {
        this.$set(this.serverErrors, 'phone', error.message);
      }
    },

    async authHandler () {
      this.$v.code.$touch();

      if (this.isCodeInvalid) {
        return;
      }

      try {
        await this.login(this.code, false);
        this.$emit('authenticated');
      } catch (error) {
        this.$set(this.serverErrors, 'code', error.message);
      }
    },

    handlePhoneInput (val) {
      this.phone = val.replace('+7 (8', '+7 (');
    },

    clearServerError (fieldName) {
      this.$v[fieldName].$reset();
      this.$delete(this.serverErrors, fieldName);
    },

    handleTimerEnd () {
      this.isTimerEnd = true;
    },

    handleResetTimer () {
      this.isTimerEnd = false;
      this.sendCodeHandler();
    },

    editPhone () {
      this.isCodeSent = !this.isCodeSent;
      this.isTimerEnd = false;
      this.codeValues = [];
      this.code = '';
      this.clearServerError('code');
    },

    async handleChangeCode (code) {
      this.code = code;
      await this.authHandler();
    }
  }
};
